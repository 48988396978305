import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const IndexPage = () => {
    return (
        <Layout pageTitle="Home">
            <div className="px-5">
                <h2>Welcome!</h2>
                <p>
                    <em>Lemmalytica</em> is a tool for exploring and understanding texts in fine-grained detail. To get started, head over to the <Link to="/app">app</Link>, enter text into the provided form, and hit "analyze". After the analyzer runs, the app will output statistics in the below categories.
                </p>
                <ul>
                    <li className="py-2">
                        <strong><em>Words</em></strong>: Statistics like word count, word length, and lexical diversity. Also a sorted list of the top words in your document by frequency count.
                    </li>
                    <li className="py-2">
                        <strong><em>Sentences</em></strong>: A quick sentence count and average sentence length, along with notable sentences like the most positive, negative, and objective, and subjective sentences.
                    </li>
                    <li className="py-2">
                        <strong><em>Readability</em></strong>: Calculations of various readability metrics, which you can use to understand how difficult your text is for readers. Each metric includes a link to a corresponding <Link to="/references">reference</Link>.
                    </li>
                    <li className="py-2">
                        <strong><em>Entities</em></strong>: Lemmalytica uses named-entity recognition (NER) to extract and count entities from various categories (people, dates, organizations, etc.) and show you which entities are the most common. This tool is perfect for getting a quick idea what your text is about.
                    </li>
                </ul>
            </div>
        </Layout>
    )
}

export default IndexPage
